<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <fertilizer-statistics />

  </b-container>
</template>
<script>

import { core } from '@/config/pluginInit'
import FertilizerStatistics from '@/components/dashboard/FertilizerStatistics.vue'

export default {
  name: 'ManagerGraphFungicides',
  middleware: ['auth', 'manager'],
  components: {
    FertilizerStatistics
  },
  metaInfo () {
    return {
      title: 'Fertilizers Graph'
    }
  },

  mounted () {
    core.index()
  }

}
</script>
